import { motion } from "framer-motion";
import Cookies from "js-cookie";
import Head from "next/head";
import { FC, useEffect } from "react";

import { authCheckStatus } from "@/actions/auth";

import Box from "@/design-system/Box";
import RequestCallbackPopup from "@/design-system/Popups/RequestCallbackPopup/RequestCallbackPopup";

//import RequestCallbackPopup from "@/design-system/Popups/RequestCallbackPopup/RequestCallbackPopup";
import { useAppDispatch, useAppSelector } from "@/hooks/hooks";

import { theme } from "@/theme";

import Banner from "./Banner";
import BannerNew from "./BannerNew";
import Footer from "./Footer/Footer";
import FooterNew from "./FooterNew/FooterNew";
import Nav from "./Nav/Nav";
import NavBarNew from "./NewNav";
import { LayoutTypes } from "./types";

const Layout: FC<LayoutTypes> = ({
  children,
  bg,
  isNewHeaderFooter,
  headerShift,
  courseName,
  showHeader = true,
  showFooter = true,
  pageTheme,
  disableCta = false,
  showBanner = true,
  showContactUs = false,
  showBimVersion = false,
}) => {
  const loginDetails = useAppSelector((state) => state?.rootReducer?.auth);
  const dispatch = useAppDispatch();

  const activePopup = useAppSelector((state) => state?.rootReducer?.popup?.activePopup);

  useEffect(() => {
    const token = Cookies.get("token");

    token && !loginDetails?.isAuthenticated && dispatch(authCheckStatus());
  }, []);

  useEffect(() => {
    !loginDetails?.isAuthenticated && dispatch(authCheckStatus());
  }, []);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, minimum-scale=1.0, initial-scale=1.0, maximum-scale=1.0"
        />
        <meta name="theme-color" content={theme.colors.primary[700]} />
        <link rel="dns-prefetch" href={process.env.NEXT_PUBLIC_PROXY_2} />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="apple-mobile-web-app-title" content="Novatr" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@oneistox" />
        <meta data-hid="author" name="author" content="Novatr" />
        <meta data-hid="og:type" name="og:type" property="og:type" content="website" />
        <meta
          data-hid="og:site_name"
          name="og:site_name"
          property="og:site_name"
          content="Novatr"
        />
      </Head>
      {showHeader && !disableCta && showBanner && <BannerNew />}
      {showHeader && isNewHeaderFooter && (
        <NavBarNew
          headerShift={headerShift}
          showContactUs={showContactUs}
          showBimVersion={showBimVersion}
        />
      )}
      <Box background={bg || theme.colors.base.white}>
        {!isNewHeaderFooter && showHeader && (
          <Nav pageTheme={pageTheme} disableCta={disableCta} showContactUs={showContactUs} />
        )}
        <motion.div
          exit={{ opacity: 0 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, type: "ease" }}
        >
          {children}
        </motion.div>

        {showFooter && !isNewHeaderFooter && <Footer disableCta={disableCta} />}

        {isNewHeaderFooter && <FooterNew />}

        <RequestCallbackPopup
          courseName={courseName}
          isOpen={activePopup === "RequestCallBackPopup"}
        />
      </Box>
    </>
  );
};

export default Layout;
