import React from "react";
import styled from "styled-components";

import { useGetNextCohortDateQuery } from "@/client/lmsApiClient";

import Badge from "@/design-system/Badge/badge";
import Box from "@/design-system/Box";
import GridContainer from "@/design-system/GridContainer";
import Icon from "@/design-system/Icon";
import { IconTypes } from "@/design-system/Icon/types";

import FlexContainer from "@/design-system-v2/FlexContainer";

import Typography from "@/design-system-v3/Typography";

import { useAppSelector } from "@/hooks/hooks";

import { formatNextCohortDate } from "@/utils/utils";

import HeaderCourseCard from "@/components/Layout/Common/HeaderCourseCard";
import HeaderNewCourseCard from "@/components/Layout/Common/HeaderNewCourseCard";
import CoursesDynamicData from "@/content/courses/dynamic-data";

import { theme } from "@/theme";

const Gray100Box = styled.div`
  position: absolute;
  top: -${theme.spacing(10)};
  bottom: 0;
  right: 0;
  width: calc(100% + 2rem);
  left: 0;
  z-index: -1;
  height: calc(100% + ${theme.spacing(30)});
  background-color: ${theme.colors.gray[100]};
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: calc(100% + 5rem);
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    width: calc(100% + (100vw - 1200px) / 2);
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    width: calc(100% + 10rem);
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.xxl}) {
    width: calc(100% + (100vw - 1520px) / 2);
  }
`;

const Lists = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  grid-gap: ${theme.spacing(2)};
`;

const listEle = [
  {
    type: "Globe",
    text: "International Exposure",
  },
  {
    type: "GameController",
    text: "Gamified Experience",
  },
  {
    type: "Chats",
    text: "Personalised Feedback",
  },
  {
    type: "UserFocus",
    text: "Personalised Skill Mapping",
  },
  {
    type: "Video",
    text: "Interactive Live Sessions",
  },
];

const TopBadgeStyle = styled(Badge)`
  align-items: center;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 1;
  border-bottom-right-radius: 0;
`;

const DefaultTopBadgeStyle = styled(Badge)`
  align-items: center;
`;

const Courses = ({ isLearnerHub }: { isLearnerHub?: boolean }) => {
  const dynamicData = CoursesDynamicData;
  const { data: nextCohortDate } = useGetNextCohortDateQuery();
  const { BIM_A, BIM_A_US, BIM_C, BIM_C_US, MCD, MCD_US, INT_DES, BIM_M } = nextCohortDate || {};
  const loginDetails = useAppSelector((state) => state?.rootReducer?.auth);
  const isNotIndia = Boolean(loginDetails?.userLocation !== "INDIA");
  const TopBadgeIcon = !isNotIndia ? TopBadgeStyle : DefaultTopBadgeStyle;

  const coursesList = [
    {
      isShow: true,
      spanCol: isNotIndia ? 4 : 3,
      pathname: "/courses/building-information-modelling",
      imgSrc: `${process.env.NEXT_PUBLIC_ASSETS}/images-v2/home/bim.png`,
      badge: (
        <TopBadgeIcon
          size="xs"
          text="Most Popular"
          colorVarient="purple"
          badgeStyle="dark"
          dotLeading={true}
        />
      ),
      heading: isNotIndia
        ? "PGP in Building Information Modelling (BIM) for Architects"
        : " BIM Professional Course for Architects V2.0",
      description:
        "Take your career to the next level by mastering the real-world application of BIM.",
      nextCohortDate: isNotIndia ? BIM_A_US?.date : formatNextCohortDate(BIM_A?.date),
      programDuration: dynamicData.find((c) => c.courseId === (isNotIndia ? "BIM_US" : "BIM"))
        ?.upcomingCohortDuration,
    },
    {
      isShow: true,
      spanCol: isNotIndia ? 4 : 3,
      pathname: "/courses/computational-design",
      imgSrc: `${process.env.NEXT_PUBLIC_ASSETS}/images-v2/home/mcd.png`,
      // badge: (
      //   <TopBadgeIcon
      //     size="xs"
      //     text="Recently Launched"
      //     colorVarient="blue"
      //     badgeStyle="dark"
      //     dotLeading={true}
      //   />
      // ),
      heading: isNotIndia
        ? "PGP in Parametric Design for Architects"
        : "Master Computational Design Course",
      description:
        "Break into an advanced career by learning to apply the power of computation to the art of design.",
      nextCohortDate: isNotIndia ? MCD_US?.date : formatNextCohortDate(MCD?.date),
      programDuration: isNotIndia
        ? dynamicData.find((c) => c.courseId === "acm_us")?.upcomingCohortDuration
        : dynamicData.find((c) => c.courseId === "acm")?.upcomingCohortDuration,
    },
    {
      isShow: true,
      spanCol: isNotIndia ? 4 : 3,
      pathname: "/courses/bim-for-civil-engineers",
      imgSrc: `${process.env.NEXT_PUBLIC_ASSETS}/images-v2/mega-menu/image_2.jpg`,
      // badge: (
      //   <TopBadgeIcon
      //     size="xs"
      //     text="New Arrival"
      //     colorVarient="orange"
      //     badgeStyle="light"
      //     dotLeading={true}
      //   />
      // ),
      heading: isNotIndia
        ? "PGP in Building Information Modelling (BIM) for Civil Engineers"
        : "BIM Professional Course For Civil Engineers",
      description:
        "Unlock new civil engineering career levels by upskilling and building on real-world projects from AEC industry.",
      nextCohortDate: isNotIndia ? BIM_C_US?.date : formatNextCohortDate(BIM_C?.date),
      programDuration: isNotIndia
        ? dynamicData.find((c) => c.courseId === "BIM_C_US")?.upcomingCohortDuration
        : dynamicData.find((c) => c.courseId === "BIM_C")?.upcomingCohortDuration,
    },
    {
      isShow: !isNotIndia,
      spanCol: isNotIndia ? 4 : 3,
      pathname: "/courses/interior-design-and-future-tech",
      imgSrc: `${process.env.NEXT_PUBLIC_ASSETS}/images-v2/home/interior.png`,
      // badge: (
      //   <TopBadgeIcon
      //     size="xs"
      //     text="Newly Launched"
      //     colorVarient="success"
      //     badgeStyle="light"
      //     dotLeading={true}
      //   />
      // ),
      heading: "Post Graduate Program in Interior Design & Future Tech",
      description: "Shape Your Interior Design Career in future ready skills.",
      nextCohortDate: formatNextCohortDate(INT_DES?.date),
      programDuration: dynamicData.find((c) => c.courseId === "INT_DES")?.upcomingCohortDuration,
    },
    {
      isShow: !isNotIndia,
      spanCol: isNotIndia ? 4 : 3,
      pathname: "/courses/bim-for-mep-engineers",
      imgSrc: `${process.env.NEXT_PUBLIC_ASSETS}/images-v2/home/mep.png`,
      badge: (
        <TopBadgeIcon
          size="xs"
          text="Newly Launched"
          colorVarient="orange"
          badgeStyle="light"
          dotLeading={true}
        />
      ),
      heading: "BIM Professional Course for MEPF Engineers",
      description: "Shape Your BIM Professional Career as an MEPF Engineer in future ready skills.",
      nextCohortDate: formatNextCohortDate(BIM_M?.date),
      programDuration: dynamicData.find((c) => c.courseId === "BIM_M")?.upcomingCohortDuration,
    },
  ];

  return (
    <>
      <Box gridColumn={isNotIndia ? "span 9" : "span 12"}>
        <GridContainer
          px={!isLearnerHub ? 32 : 3}
          pt={!isLearnerHub && theme.spacing(6)}
          alignItems="flex-start"
          spacing={theme.spacing(8)}
        >
          {coursesList.map((c) => (
            <React.Fragment key={c.pathname}>
              {/* {c.isShow ? <HeaderCourseCard  {...c} /> : null} */}
              {c.isShow ? (
                !isNotIndia ? (
                  <HeaderNewCourseCard newHeader={!isLearnerHub} {...c} />
                ) : (
                  <HeaderCourseCard {...c} />
                )
              ) : null}
            </React.Fragment>
          ))}
        </GridContainer>
        {!isLearnerHub && !isNotIndia && (
          <FlexContainer
            mt={theme.spacing(10)}
            p={theme.spacing(6)}
            background={theme.colors.purple[50]}
            justifyContent={"space-around"}
          >
            {listEle.map((ele) => (
              <Lists key={ele.type}>
                <Icon
                  color={theme.colors.purple[500]}
                  type={ele.type as IconTypes}
                  varient={"line"}
                  size={24}
                />{" "}
                <Typography varient="bodys" weightVarient="medium">
                  {ele.text}
                </Typography>
              </Lists>
            ))}
          </FlexContainer>
        )}
      </Box>
      {!isLearnerHub && isNotIndia && (
        <Box
          gridColumn={"span 3"}
          py={theme.spacing(15)}
          px={theme.spacing(10)}
          backgroundColor={theme.colors.purple[50]}
        >
          <div
            style={{
              fontSize: "12px",
              fontWeight: "500",
              lineHeight: "20px",
              textTransform: "uppercase",
            }}
            color={theme.colors.gray[600]}
          >
            Innovative Learning
          </div>
          <Typography
            varient="heading5"
            weightVarient="semibold"
            color={"purple.600"}
            mb={theme.spacing(6)}
          >
            <Box as="span" color={"#0C111D"}>
              An
            </Box>{" "}
            Unforgettable Experience
          </Typography>
          {listEle.map((ele) => (
            <Lists key={ele.type} style={{ marginBottom: theme.spacing(5) }}>
              <Icon
                color={theme.colors.purple[500]}
                type={ele.type as IconTypes}
                varient={"line"}
                size={24}
              />{" "}
              <Typography varient="bodys" weightVarient="medium">
                {ele.text}
              </Typography>
            </Lists>
          ))}
          {/* <Gray100Box /> */}
        </Box>
      )}
    </>
  );
};

export default Courses;
