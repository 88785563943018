import dayjs from "dayjs";
import { useCallback } from "react";

import { openPopup } from "@/actions/popup";

import Box from "@/design-system/Box";
import UnstyledButton from "@/design-system/Button/UnstyledButton";
import Icon from "@/design-system/Icon";
import useMediaQuery from "@/design-system/useMediaQuery";

import FlexContainer from "@/design-system-v2/FlexContainer";

import { useAppDispatch, useAppSelector } from "@/hooks/hooks";

import Typography from "@/design-system-supernova/Typography";

import { theme } from "@/theme";

const BannerNew = () => {
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.md})`);
  const loginDetails = useAppSelector((state) => state?.rootReducer?.auth);
  const isNotIndia = Boolean(loginDetails?.userLocation !== "INDIA");
  const isRow = Boolean(loginDetails?.userLocation === "ROW");

  const getDeadline = useCallback(() => {
    let deadline = dayjs();

    if (dayjs().date() > 25) {
      deadline = dayjs().endOf("month");
    } else if (dayjs().date() >= 15) {
      deadline = dayjs().date(25);
    }

    return deadline.format(isRow ? "MMM Do" : "Do MMM");
  }, [loginDetails?.userLocation]);

  if (dayjs().date() < 15) {
    return null;
  }

  return (
    <UnstyledButton
      onClick={() => dispatch(openPopup("RequestCallBackPopup"))}
      style={{ width: "100%" }}
    >
      <Box
        position="relative"
        background={"linear-gradient(90deg, #7860FC 0%, #21B7DE 50%, #7FEB8C 100%)"}
        zIndex={1201}
        p={{ _: "16px", md: "14px" }}
      >
        <FlexContainer
          alignItems={isMobile ? "start" : "center"}
          justifyContent={isMobile ? "start" : "center"}
          flexDirection={isMobile ? "column" : "row"}
          gap={theme.spacing(1)}
        >
          <Typography
            weightVarient="regular"
            varient="bodyl"
            color={theme.colors.base.white}
            textAlign={isMobile ? "left" : "center"}
            display="inline"
          >
            {`⚡ Hurry! ${isNotIndia ? "Exclusive scholarships available" : "Last Chance for Scholarship"} - Enroll before ${getDeadline()} ⚡️`}
          </Typography>
          <FlexContainer>
            <FlexContainer
              alignItems="center"
              gap={theme.spacing(1)}
              ml={isMobile ? theme.spacing(1) : theme.spacing(2)}
              display={isMobile ? "flex" : "inline-flex"}
            >
              <Typography
                weightVarient="semibold"
                varient="bodyl"
                color={isMobile ? theme.colors.purple[600] : "#344054"}
                as={"span"}
                style={{
                  textDecoration: isMobile ? "underline" : "none",
                }}
              >
                Apply Now
              </Typography>
              {!isMobile && (
                <Icon type="arrow-up-right" color={theme.colors.gray[700]} varient="line" />
              )}
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
      </Box>
    </UnstyledButton>
  );
};

export default BannerNew;
